import { useQuery, useMutation } from '@apollo/client';
import { Comment, Button, Checkbox, Form, Input, message, Modal } from 'antd';
import { useContext, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { GET_MESSAGES, CREATE_MESSAGE, PUBLISH_MESSAGE } from '../Queries';
import TinderProvider, { TinderContext } from './Tinder/TinderProvider';
import { useQueryParams, WithApollo } from './Tinder/Tinder';
import Header from './Header';

const Wrapper = styled.div``;

const MessagesInner = () => {
  const { userId } = useContext(TinderContext);
  const [visible, setVisible] = useState(false);
  const [posting, setPosting] = useState(false);
  const { data, loading } = useQuery(GET_MESSAGES, {
    pollInterval: 5000,
  });

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [publishMessage] = useMutation(PUBLISH_MESSAGE, {
    refetchQueries: [
      {
        query: GET_MESSAGES,
      },
    ],
  });

  const query = useQueryParams();
  const full = query.get('full');

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden p-6">
      <main className="grow">
        <section>
          <div className="max-w-6xl mx-auto sm:px-6 relative">
            <h1 className="h1" data-aos="fade-up" style={{ color: 'white', display: 'flex ' }}>
              <span style={{ flex: 1, color: '#999' }}>Mensajes Publicos</span>
            </h1>
            <Modal title="Agregar Mensaje" visible={visible} footer={null} onCancel={() => setVisible(false)}>
              <Form
                initialValues={{
                  anonymous: true,
                }}
                onFinish={async v => {
                  setPosting(true);

                  const m = await createMessage({
                    variables: {
                      ...v,
                      authorId: userId,
                    },
                  });

                  console.log('created');
                  console.log(m);

                  await publishMessage({
                    variables: {
                      id: m.data.createMessage.id,
                    },
                  });

                  message.info('Enviado!');
                  setPosting(false);
                  setVisible(false);
                }}
              >
                <Form.Item name="content">
                  <Input.TextArea placeholder="Quiero decir que..." required />
                </Form.Item>
                <Form.Item name="anonymous" valuePropName="checked">
                  <Checkbox>Anonimo</Checkbox>
                </Form.Item>
                <Button htmlType="submit" block loading={posting}>
                  Publicar
                </Button>
              </Form>
            </Modal>
            <div>
              <Button block size="large" type="primary" onClick={() => setVisible(true)} style={{ margin: '20px 0px' }}>
                Agregar Mensaje
              </Button>
              <div>
                {data.messages.map(m => {
                  return (
                    <Wrapper>
                      <Comment
                        style={{
                          color: 'white',
                        }}
                        datetime={<div style={{ fontSize: 16, marginBottom: 5 }}>{moment(m.createdAt).fromNow()}</div>}
                        key={m.id}
                        author={
                          <div style={{ color: 'white', fontSize: 16 }}>
                            {!full && m.anonymous ? 'Anonymous' : m.author.name}
                          </div>
                        }
                        avatar={!full && m.anonymous ? null : m.author.image.url}
                        content={
                          <div
                            style={{
                              backgroundColor: '#75BBF7',
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 15,
                              borderBottomLeftRadius: 15,
                              borderBottomRightRadius: 15,
                              color: 'black',
                              fontSize: 18,
                              padding: '10px 20px',
                            }}
                          >
                            {m.content}
                          </div>
                        }
                      />
                    </Wrapper>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

const Messages = () => {
  return (
    <WithApollo>
      <TinderProvider>
        <Header />
        <div className="flex flex-col min-h-screen overflow-hidden">
          <main className="grow">
            <div className="relative mt-12 pb-10 md:pb-16">
              <MessagesInner />
            </div>
          </main>
        </div>
      </TinderProvider>
    </WithApollo>
  );
};

export default Messages;
