import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import Process from '../partials/Process';
import FeaturesHome from '../partials/FeaturesHome';
import Tabs from '../partials/Tabs';
import Target from '../partials/Target';
import News from '../partials/News';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import People from '../partials/People';
import Donde from '../partials/Donde';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        <People />
        <Donde />
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-10 pb-12 md:pt-16 md:pb-20">
              <h2 className="h1 mb-8 text-center" id="juegos">
                Juegos
              </h2>
              <div style={{ justifyContent: 'center', display: 'flex', marginTop: 40 }}>
                <video width="320" height="240" controls style={{ borderRadius: 20, overflow: 'hidden' }}>
                  <source src="https://nt-cdnn.s3.us-east-1.amazonaws.com/messimessage.mp4" type="video/mp4" />
                </video>
              </div>
              <h3 className="text-center h2 mt-4">Coming soon...</h3>
            </div>
            {/* <Link to="/juegos">
              <Button type="primary" size="large" block>
                A jugar!
              </Button>
            </Link> */}
          </div>
        </section>
        {/* <Tabs />
        <Target />
        <News />
        <Newsletter /> */}
        <br />
        <br />
        <br />

        <br />
        <br />
        <br />
      </main>

      {/*  Site footer */}
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
