import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ACTO_1, ACTO_2 } from '../Config';
import Dropdown from '../utils/Dropdown';

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block flex" aria-label="Egresados 2001">
              <img src={require('../images/logo.png').default} alt="Egresados 2001" className="h-8" />
              <span style={{ marginLeft: 10 }}>2001</span>
            </Link>
          </div>

          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Home
                </Link>
              </li>
              <li>
                <a
                  href="/#donde"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  ¿Donde?
                </a>
              </li>
              <li>
                <Link
                  to="/board"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Mensajes
                </Link>
              </li>
              <li>
                <Link
                  to="/media"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Media
                </Link>
              </li>
              {/* <Dropdown title="Juegos">
                <li>
                  <a
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ACTO_1}
                  >
                    Votemos
                  </a>
                </li>
                <li>
                  <a
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ACTO_2}
                  >
                    Trivia
                  </a>
                </li>
                <li>
                  <Link
                    to="/tinder"
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                  >
                    Match
                  </Link>
                </li>
              </Dropdown> */}
            </ul>
          </nav>

          <div className="md:hidden">
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="/" className="flex text-gray-300 hover:text-gray-200 py-2">
                    Home
                  </Link>
                </li>
                <li>
                  <a href="/#donde" className="flex text-gray-300 hover:text-gray-200 py-2">
                    ¿Donde?
                  </a>
                </li>
                <li>
                  <Link to="/board" className="flex text-gray-300 hover:text-gray-200 py-2">
                    Mensajes
                  </Link>
                </li>
                <li>
                  <Link to="/media" className="flex text-gray-300 hover:text-gray-200 py-2">
                    Media
                  </Link>
                </li>
                {/* <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Juegos</span>
                  <ul className="pl-4">
                    <li>
                      <a
                        href={ACTO_1}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Votemos
                      </a>
                    </li>
                    <li>
                      <a
                        href={ACTO_2}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Trivia
                      </a>
                    </li>
                    <li>
                      <Link to="/tinder" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">
                        Match
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
