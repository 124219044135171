import { useLazyQuery, useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePersistedState } from '../../hooks';
import { GET_PEOPLE } from '../../Queries';
import { Login, WithApollo } from './Tinder';

export const TinderContext = React.createContext({});

const TinderProviderInner = ({ children }) => {
  const [userId, setUserId] = usePersistedState(null);
  const [people, setPeople] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fetchPeople] = useLazyQuery(GET_PEOPLE);

  useEffect(() => {
    fetchPeople({
      onCompleted: d => {
        setPeople(d.people);
      },
    });
  }, [fetchPeople]);

  if (!people) return <div>Loading...</div>;

  return (
    <TinderContext.Provider
      value={{
        userId,
        setUserId,
        loading,
        setLoading,
        people,
        setPeople,
      }}
    >
      {people && userId ? children : <Login />}
      {userId && (
        <div style={{ position: 'fixed', bottom: 20, right: 20 }}>
          <Button className="btn-sm text-white bg-pink-500" style={{ width: '100%' }} onClick={() => setUserId(null)}>
            Log Out
          </Button>
        </div>
      )}
    </TinderContext.Provider>
  );
};

const TinderProvider = props => {
  return (
    <WithApollo>
      <TinderProviderInner {...props} />
    </WithApollo>
  );
};

export default TinderProvider;
