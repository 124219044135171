import React from 'react';
import styled from 'styled-components';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { isMobile } from '../partials/Tinder/Tinder';
import Header from '../partials/Header';

const Thumb = styled.img`
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  height: ${() => (isMobile() ? window.innerWidth / 2 - 15 : '200')}px;
  margin: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #555;
    transform: translateY(-5px);
  }

  .slick-dots button {
    background-color: white;
  }
`;

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  justify-content: center;
`;

const Media = () => {
  const images = [
    'https://lh3.googleusercontent.com/jlB0J7eHxrf519ADiTPgh67e83G91KhSzh8qqTqaCgOGCzgYovqcRi0ui0QgIQan8t-B1CnaV_xYN6RZVThQS2rpy5ZqbP-W8TO9iAFc1SM_jPF_BOYNeyIAv2C8mYhAiq9TF9fZlRo=w1920-h1080',
    'https://lh3.googleusercontent.com/bqu4YO-HOhuYuuDEqQDRnCWCfyQHeQLkYaADUdDR0vMerCpII6rrSSALG4MstxkKxZASPFncgv3_-QZtwM7dYOJHaIoLX20733FbzmQeVGJxjBR7DtQ4TcST-2cuXynpCTAOLo37IA0=w1920-h1080',
    'https://lh3.googleusercontent.com/G1lv9aJr3GabMK9b-bZha5Oq0pXvY4Sp3624zV_MvnWDICrz0jMYkmkw2PrnM3dWoOi2h-j2jhDZDGY_W7J6bfTSsUyaQQ_h_MGTxsr5Q7v2vVlDCSEW8nebwzf0_5pvau-1RgKf8gI=w1920-h1080',
    'https://lh3.googleusercontent.com/pf6gcjGkMLHiMMCEqoFUTarRyZW6oMArBCAai6hjYDzm-PuCa_UsyHTQQM9qBsonRNJa53fwSYr32jyD38rweK_etFWTvXK4M_BlP_tqzEvKx8W4_aSlcIPCf0ueaW-8lhJRjUl281Y=w1920-h1080',
    'https://lh3.googleusercontent.com/iRrlDaze_-7EhkYwRBJ-EKzMbPlApRuvUb5CLTxi24g6rkqm2bJmoYna-e1jaoT2XhZy6zzfjxo-JCJkv9gngKVxMEBQIx7OrBO7YOnG5-AyD26JMz5wV_JmvKgprbki2hHwbUDL2Xw=w1920-h1080',
    'https://lh3.googleusercontent.com/0M3JI2vMC7Dp3ihbHPeMWLP7qGXeafSpLGTbLcxIK6YkGlO_50cTq0FD5rpbpB8R_1IELLffhIQne9SXZJaRkJkCUYJAZfm7U0sciyTziZZ-a99hsnTmBpQsBFyYwlczuQZpUfvSHBk=w1920-h1080',
    'https://lh3.googleusercontent.com/sAKXfqGJLRoNbQpMnBT543MNgdZgr0ZzTJbk6KJlycDKGfDhbA5EPZXJQi-G4T1AuKnRRoRv8lurgMjA3OKbKL8fNrUYwChLCmEaxKtYbiTAs1ovYwKkAMscLNxFsLTsoRUUnplHbi0=w1920-h1080',
    'https://lh3.googleusercontent.com/cjIx7s17NhZyTqNPZRVo6lOW7xTLLh9Bpug54qFJiS7fp7HrReiwCcp7qExXLRRq4bGXUUU5iwPrmqLOUbiecHvCRWK3W7XOvZbBbusUF-Pb0H_PkVV7leqx3e7uBADJLT_bm0w7yzA=w1920-h1080',
    'https://lh3.googleusercontent.com/hsWakL8gdM0Uu0MN9n-NBDoE8rJ0_3wkmkFIja0k1LmGn_4issrvao5R6FXJPltI24RZTWwLxKNxPtc0fqbprqTrouNxtGRZjSKPYRxy2z_RRT6oUWfN68uOy5UtMOLRlP4u7kw6Vls=w1920-h1080',
    'https://lh3.googleusercontent.com/cdlIpzbFk1-fMTtQjUVTRyNuAOtBSe2EhSl3TfJ20idE1DNUSI2GyQ5qyfW1NKnqsjAC7l0TmVoi7TUd4W2f94x0CollebGltJ4yicjlLRpJ6d0Krf8eoaWFH9O3rIRJgSShtLruseA=w1920-h1080',
    'https://lh3.googleusercontent.com/sfe49se_P-QdikWLscqgYigScKsdHZNMyndZ5e1NQBDoJb1i5bkpwZlUrwteKiWKKO2dfjZG3JpPSsCf4gk4o_2z2yg41z2M1So3ywqPEs4VTpqt5wdjXOaLx4F_YY2-PKLloncbHhQ=w1920-h1080',
    'https://lh3.googleusercontent.com/vC7vgo5zYW6I49U3QlAOEi6d9swexfgvHEYkGde8DT_DvKJkh-pSZ67MlKcD_SzTtmfgYqanT92NVzjEFEkNBVZrk8hv2gS-522vxkn-OILOiRC7YVBA6yotqv4HSZ11oIErwwC7MyU=w1920-h1080',
    'https://lh3.googleusercontent.com/C5QRR8QrZKFGvB97HFb8C1yEGpcd5th89MPX9iL4z2ld4Oh5NtOmug_dgNCMzwbKdfofuQU3W2xNR5xxPoZL7tVa606ZfzImnIN822JhXdgEjpd_bykUQXCYyVT54WmuFCLT0Hr0MHg=w1920-h1080',
    'https://lh3.googleusercontent.com/xpGPOq3sVXHFRbfFc_SxX6KqMY4KZhwQ5SVOFUZWPhqEtxgZ1w6uNOZYJwBabWLtWX-fK0vb5PL7TPsbl1-kAkSUNwVulVcNuvwKtiq3-3R6A9ci7Q-Mpn2gsljB78pym-OyL2E-5BU=w1920-h1080',
    'https://lh3.googleusercontent.com/gsXQX34PTrzkcx2pEqK_D-szrooPZziHdnhAngLFJ6oY5cScTFvMBzwe05XvAvavGhSZRkuoQWT8xDu3o1uNk2afc3m4_RArABdRjuvkxxZsDDUPar7HSOl0emK80R9r3VutE2c7hP4=w1920-h1080',
    'https://lh3.googleusercontent.com/zNV_buoF_Z93BM6g4TeORexamCSfxvuHmcwlPijEi05wjesUcWAeEXPV1McRtbDHnQefnImTW-ji-0os34TcwYkFbrZJMHDC6JNQzaPkXpEns2bxGHAhKMKXyaubFNii6sBM65_BJSo=w1920-h1080',
    'https://lh3.googleusercontent.com/HcKM82udOu-W3ystF9Q923vI98UopGJaeFpNI54msg1Kvh_1-hvyCJlspjtiRvucD4MbXdV-Gk1qfwYnLlTt2ux2Y4T8jdfUEbzmzwgafjLemYKcQBEA9_dmrUjQ9LbBTvntVp_dZjs=w1920-h1080',
    'https://lh3.googleusercontent.com/YNufsSl39km-g7233C_co-zJWbaZ8yWfs4c9oYOOaX34w61MYsvmR8trLHIazq7aEB20Zxp-18x89Z5CIg9Kxq_-gzzYAxcWd3YRAaIta3q3R1-Vj51mvTQYYfreXZA48vB_0MdzQIY=w1920-h1080',
    'https://lh3.googleusercontent.com/u57sxNgvmv6w0FLFJurLmTOWtw8MLzaGJ2CIw7Bc5NRHN30eHgrg5-8LVvHX3Z-f7DJUsBFjC-0v7bR8I-wSVI23ypZYHSqZ3xzCh6H9ZAg-NMRcx1d5vWOySGvH42wefckeFz99A_c=w1920-h1080',
    'https://lh3.googleusercontent.com/VYRSF2kPtLOLkgEeKL6dLgfW48SFVYBOz1Wo6fcSQUfnLmDftud8f0prItB2u7FY8lEdA4kmBIyBnC9pMjykWOo7_SeZMiWsYaoO_bNe85M4MVWPS2mtjL_knEfoL7HN7Rf1AeD4lr8=w1920-h1080',
    'https://lh3.googleusercontent.com/ylEKu7RFGj1Z3_t9SLM-SGTCoKRCEJGNASetecTdDWr4sLndvuV0PGt6fV-aYiStZ95IhOySfbU-Eosn4HexuPmS542Vk5x2KpRad0ol-bysC6w4uRR94B9VjiReEE-kkbxvj9dxdkI=w1920-h1080',
    'https://lh3.googleusercontent.com/kIXYyEKo2Z8zwze2uicmM6YlJdAa1V0uHp3dQ-mXO10xrvRH4fB0Zhhu6p29KJOX0ZUfhKiq9AhzyboFIurwz3DYkyjVmAd_JZ-LLn5RrX3C-UeUjQL17RGep5AOnp0eiQ4q0fsKhDc=w1920-h1080',
    'https://lh3.googleusercontent.com/RocDxd3NdOoypQf4-tNKvAwNbO7rwh2wWqOhcQg4ilt4Vc6m5TVqwnIAS6J8oJqbKHDmnLVMIJfMe4fOayqMcy_7EVJuMvK1AtTMLCeEDQk29cZbG6MqqMOf2__gEn7kIowlnO9EURI=w1920-h1080',
    'https://lh3.googleusercontent.com/riLW6YzOF-x_mWfo6g05LtjgrCIaWkAxCJ3MVjT5xWaUg38fkDdmoMl1h0iBWfs5ZTe-0uY14h_aNPzS84QHtMGFBBe3n__RjGFYlosB5RtH0_C4LGeugd2CMEbhKmQT8tX72o80gkA=w1920-h1080',
    'https://lh3.googleusercontent.com/LURzaWSy76RSBp-nFfLMKzjuR3JyqGB6QqcPKCM_Ke1s837GUEZ22GELOk8lLhfg8gxd8w9G_k83nz1WkvT1lLHVfKfH-SZeWf4n5DQw6rwASxaw07cNHgGKYXwhdDeHo61xj4EsAfw=w1920-h1080',
    'https://lh3.googleusercontent.com/CtqUIw4HJMwpsiqIVVWGBTzrk8TVIY2S9djRtIW5KdStwHAQJ-3rfDW6hq3xCCPFHgXUoCLWzcEk4bv_u0GCTRYJXE2OkbuO3oFhVHe-fhHP4B6ga_DsOa_5BclFin6G4zPl3yRe7DQ=w1920-h1080',
    'https://lh3.googleusercontent.com/bCwGSN72IAPlGWtsTRbiWcuuYwGnJiDLf5fscCyO_I7Y6QPdMhSLyKydzzxvD1dyglkhnLeUD2nIZUQjXq9HX7YOPJGqm8lN2nW7_CWvOJk3Xn0POxvu5Mor3A_om9L1zGHZBKyj21U=w1920-h1080',
    'https://lh3.googleusercontent.com/ehKJ2jBS0KdeMTizn8BknkmQ4H08LtY9JyA4iz6YbRlBeAcRGMdml5WgE9_GaMS71CwXbGDHB3mYsTr2TCgMUUo58Ife55xZgH4uyLYI41VSkWxGujcb7Ei91NQYwC6HymdpRVASaIs=w1920-h1080',
    'https://lh3.googleusercontent.com/elm2koB5Sy1pzaZ4WVGR2tQZvxbtdyC27eMMVL48wwItIKmDTHnsVAITxq2zLseO8f8VGrInC5pLWYFAI8_35bPsweYsvgJSg2AY1LuCqbVtJWYRLlLjgmmP-pGki3gowSNEkM6R4EI=w1920-h1080',
    'https://lh3.googleusercontent.com/g8KuAk-5NP5MHqFikM5vjQFcprboJuZLpaVDbBifNkJlUHW0KAB_aQpLC3u_7GEhg_JSXK3NvhcHQNFSGwKASK4ynjFpZVNOipZSu2ANxTF9jSudbqzGWWhlt_eboh05BGz0O9xPQSs=w1920-h1080',
    'https://lh3.googleusercontent.com/R981wd3xqxTRhJo0ACXK5xb8ch_sxJ0mA5JYpQr5U8yNrpAvBaGs6BygupmB8VZ170XWfqzCVM2gsRfblStkI78mwD6qnD8i0izfDXmV7V6j61D9NqxXwECheHqRn-MhARlN9rXo-Xc=w1920-h1080',
    'https://lh3.googleusercontent.com/72AXiUmcZKR2uSNguRK4QiR7jEiImxQbu_Y4pipkJgdA5saYin8gZE9FlC-JQkTJ0ke09gcLTXk5LIupurmcueckwofZH5WRRSNp8Ak5zrsniaK72TmJ1emiNiktLvtZ909MeUGp46M=w1920-h1080',
    'https://lh3.googleusercontent.com/1TWWu8godATQ4ut6GlAxXVXZHLl4EXH0LlYXA3yUuQuIUj5zinaSCghsmiVVrL2Ww8LLBMlE4gDmxKqAr0W0qf-vZpWk0p_U8MkgkpV4EhpKU6V1qWa3UDlQ4JOGrS1jqN3ttVQ0S50=w1920-h1080',
  ].map(src => <Thumb key={src} src={src} />);

  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <Header />
        <div className="flex flex-col min-h-screen overflow-hidden">
          <main className="grow">
            <div className="relative mt-12 pb-10 md:pb-16">
              <div className="flex flex-col min-h-screen overflow-hidden p-6">
                <main className="grow">
                  <section>
                    <div className="max-w-6xl mx-auto sm:px-6 relative">
                      <h1 className="h1 text-center" data-aos="fade-up" style={{ color: 'white', display: 'flex ' }}>
                        <span style={{ flex: 1, color: '#999' }}>La Previa</span>
                      </h1>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px 60px 0px' }}>
                      <iframe
                        src="https://open.spotify.com/embed/playlist/79EIIXf7n5hcbVvpZOHeSo?utm_source=generator&theme=0"
                        width="380"
                        height="380"
                        frameBorder="0"
                        allowfullscreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                      ></iframe>
                    </div>
                    <Wrapper>{images}</Wrapper>
                  </section>
                </main>
              </div>
            </div>
          </main>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default Media;
