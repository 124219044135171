import { useQuery } from '@apollo/client';
import { GET_PEOPLE } from '../Queries';
import { isMobile, WithApollo } from './Tinder/Tinder';

const PeopleInner = () => {
  const { data, loading } = useQuery(GET_PEOPLE);

  if (loading) return null;

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {data.people.map(person => {
            return (
              <div
                key={person.id}
                style={{
                  borderRadius: 15,
                  overflow: 'hidden',
                  border: 'none',
                  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.6)',
                  backgroundImage: `url(${person.image?.url})`,
                  backgroundSize: 'cover',
                  backgroundColor: '#222',
                  backgroundPosition: 'center',
                  flex: `0 0 calc(100%/${isMobile() ? 2 : 6} - 20px)`,
                  width: 'calc(100%/5)',
                  height: 150,
                  position: 'relative',
                  margin: '10px',
                }}
                className="mt-4"
              >
                <div
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    left: 10,
                    fontWeight: 'bold',
                    fontSize: 20,
                  }}
                >
                  {person.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const People = () => {
  return (
    <WithApollo>
      <PeopleInner />
    </WithApollo>
  );
};

export default People;
