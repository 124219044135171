import React from 'react';
import { Link } from 'react-router-dom';
import { ACTO_1, ACTO_2 } from '../Config';

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">
          <h2 className="h1 mb-8 text-center" id="juegos">
            Juegos
          </h2>

          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
            <div className="relative flex flex-col items-center" data-aos="fade-up">
              <h4 className="h4 mb-2">Votemos</h4>
              <p className="text-lg text-gray-400 text-center">
                El tiempo ha pasado, la vida nos cambió. Algunos parecen no envejecer, otros como el vino se ponen mejor
                con los años. El voto es democratico y anonimo.
              </p>
              <a
                className="btn bg-purple-600 text-white mt-4 mb-8"
                target="_blank"
                rel="noopener noreferrer"
                href={ACTO_1}
              >
                Primer Acto
              </a>
            </div>

            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
              <h4 className="h4 mb-2">Trivia</h4>
              <p className="text-lg text-gray-400 text-center ">
                Pasaron 20 años, mas de la mitad de nuestras vidas. Casi que nos volvemos a conocer, aca algunas
                preguntas para que recordar anecdotas y descubrir cosas que quizas no sabias
              </p>
              <a
                className="btn bg-purple-600 text-white mt-4 mb-8"
                target="_blank"
                rel="noopener noreferrer"
                href={ACTO_2}
              >
                Segundo Acto
              </a>
            </div>

            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200">
              <h4 className="h4 mb-2">Encontrá tu Match</h4>
              <p className="text-lg text-gray-400 text-center">
                ¿Es posible viajar en el tiempo? No lo sabemos, pero ¿que hubieses hecho si en el 2001 contabas con
                Tinder? Te lo trajimos para que puedas comprobarlo. ¿Te la bancas?
              </p>
              <Link to="/tinder" className="btn bg-purple-600 text-white mt-4 mb-8">
                Tercer Acto
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
