import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';

import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';
import Tinder, { Db, PersonCreate } from './partials/Tinder/Tinder';
import Messages from './partials/Messages';
import Games from './pages/Games';
import Header from './partials/Header';
import Media from './pages/Media';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: true,
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/media">
          <Media />
        </Route>
        <Route exact path="/tinder">
          <Tinder />
        </Route>
        <Route exact path="/tinderdb">
          <Db />
        </Route>
        <Route exact path="/create">
          <PersonCreate />
        </Route>
        <Route exact path="/messi">
          <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Site header */}
            <Header />
            <div style={{ justifyContent: 'center', display: 'flex', marginTop: 80 }}>
              <video width="320" height="240" controls style={{ borderRadius: 20, overflow: 'hidden' }}>
                <source src="https://nt-cdnn.s3.us-east-1.amazonaws.com/messimessage.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </Route>
        <Route exact path="/juegos">
          <Games />
        </Route>
        <Route exact path="/board">
          <Messages />
        </Route>
        <Route path="/features">
          <Features />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/blog-post">
          <BlogPost />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
