import { gql } from '@apollo/client';

export const GET_PEOPLE_FULL = gql`
  query GetPeopleFull {
    people(orderBy: name_ASC) {
      id
      name
      lastFourPhone
      house
      likes {
        person {
          name
        }
      }
    }
  }
`;

export const GET_PEOPLE = gql`
  query GetPeople {
    people(orderBy: name_ASC) {
      id
      name
      lastFourPhone
      house

      image {
        id
        url
      }
    }
  }
`;

export const GET_PERSON = gql`
  query GetPerson($id: ID!) {
    person(where: { id: $id }) {
      id
      name
      house

      image {
        id
        url
      }

      likes {
        id

        person {
          id
          name
          house

          image {
            id
            url
          }

          likes {
            id
            person {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// Mutations
export const PUBLISH_ASSET = gql`
  mutation PublishAsset($id: ID!) {
    publishAsset(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;

export const CREATE_PERSON = gql`
  mutation CreatePerson($name: String!, $lastFourPhone: String!, $assetId: ID!, $house: House) {
    createPerson(
      data: { name: $name, lastFourPhone: $lastFourPhone, house: $house, image: { connect: { id: $assetId } } }
    ) {
      id
    }
  }
`;

export const PUBLISH_PERSON = gql`
  mutation PublishPerson($id: ID!) {
    publishPerson(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;

export const CREATE_LIKE = gql`
  mutation CreateLike($authorId: ID!, $personId: ID!) {
    createLike(data: { author: { connect: { id: $authorId } }, person: { connect: { id: $personId } } }) {
      id
    }
  }
`;

export const PUBLISH_LIKE = gql`
  mutation PublishLike($id: ID!) {
    publishLike(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;

export const DELETE_LIKE = gql`
  mutation DeleteLike($id: ID!) {
    deleteLike(where: { id: $id }) {
      id
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($authorId: ID!, $anonymous: Boolean, $content: String!) {
    createMessage(data: { anonymous: $anonymous, author: { connect: { id: $authorId } }, content: $content }) {
      id
    }
  }
`;

export const PUBLISH_MESSAGE = gql`
  mutation PublishMessage($id: ID!) {
    publishMessage(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages {
    messages(orderBy: createdAt_DESC) {
      id
      createdAt
      content
      anonymous
      author {
        id
        name
        image {
          id
          url
        }
      }
    }
  }
`;
