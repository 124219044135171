import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ACTO_1, ACTO_2 } from '../Config';

function Donde() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4" data-aos="fade-up" id="donde">
              ¿Donde?
            </h2>
            <div className="text-xl" style={{ textAlign: 'center' }}>
              <a href="https://goo.gl/maps/JAdTgQj4wCEL5PJR8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9426213990478!2d-58.44309308477105!3d-34.580318380465506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb51fe0bdfc69%3A0x6b2b15a52e58df67!2sCasa%20Maria%20Vermuteria!5e0!3m2!1sen!2sar!4v1639495613547!5m2!1sen!2sar"
                  width={window.innerWidth - 40}
                  height="450"
                  style={{ border: 0, margin: 'auto', maxWidth: 600, width: window.innerWidth - 40 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
                <br />
                <p>Casa Maria Vermuteria</p>
                <p>Honduras 6055, Buenos Aires</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Donde;
