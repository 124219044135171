import { useMemo, useState, useContext } from 'react';
import { Avatar, Button, Form, Input, message, Modal, Select, Spin, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, useQuery, useMutation } from '@apollo/client';
import { HeartFilled, HeartOutlined, LoadingOutlined } from '@ant-design/icons';

import Header from '../Header';
import {
  CREATE_LIKE,
  CREATE_PERSON,
  DELETE_LIKE,
  GET_PEOPLE_FULL,
  GET_PERSON,
  PUBLISH_ASSET,
  PUBLISH_LIKE,
  PUBLISH_PERSON,
} from '../../Queries';
import TinderProvider, { TinderContext } from './TinderProvider';
import { GRAPHQL } from '../../Config';

export const isMobile = () => {
  if (window.innerWidth <= 600) return true;

  if (typeof navigator === 'undefined') return false;

  let check = false;
  (function checkIfMobile(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const COLORS = {
  South: 'green',
  North: '#266DC6',
  East: '#F7AC03',
  West: '#E63C2D',
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

// Admin --------------------------------------------------
export const Db = () => (
  <WithApollo>
    <DbInner />
  </WithApollo>
);

const DbInner = () => {
  const { data, loading } = useQuery(GET_PEOPLE_FULL);
  const query = useQueryParams();
  const full = query.get('full');

  if (loading) return <div>Loading...</div>;

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'likes',
      render: likes => likes.length,
    },
  ];

  if (full) {
    columns.push({
      dataIndex: 'likes',
      render: likes => likes.map(({ person }) => person.name).join(', '),
    });
  }

  return <Table rowKey="id" size="small" pagination={false} dataSource={data.people} columns={columns} />;
};

export const PersonCreate = () => (
  <WithApollo>
    <PersonCreateInner />
  </WithApollo>
);

const PersonCreateInner = () => {
  const [loading, setLoading] = useState(false);
  const [publishAsset] = useMutation(PUBLISH_ASSET);
  const [publishPerson] = useMutation(PUBLISH_PERSON);
  const [createPerson] = useMutation(CREATE_PERSON);

  return (
    <>
      <Form
        style={{ padding: 20 }}
        onFinish={async v => {
          setLoading(true);

          const form = new FormData();
          const file = document.getElementById('photo').files[0];
          form.append('fileUpload', file);

          const d = await fetch(`${GRAPHQL}/upload`, {
            method: 'POST',
            body: form,
          }).then(r => r.json());

          message.info('Created Asset');

          await publishAsset({
            variables: { id: d.id },
          });

          message.info('Published Asset');

          const r = await createPerson({
            variables: {
              name: v.name,
              lastFourPhone: v.lastFourPhone,
              house: v.house,
              assetId: d.id,
            },
          });

          message.info('Created Person');

          publishPerson({
            variables: {
              id: r.data.createPerson.id,
            },
          });

          message.info('Done!');
          setLoading(false);
        }}
      >
        <h1 className="h1 mb-4">Add Person</h1>
        <Form.Item name="name">
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="lastFourPhone">
          <Input placeholder="Last 4 number" />
        </Form.Item>
        <Form.Item name="house">
          <Select>
            <Select.Option value="North">North</Select.Option>
            <Select.Option value="South">South</Select.Option>
            <Select.Option value="East">East</Select.Option>
            <Select.Option value="West">West</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="photo">
          <Input type="file" id="photo" />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={loading}>
          Submit
        </Button>
      </Form>
    </>
  );
};

// Admin --------------------------------------------------
export const Login = () => {
  const { setUserId, people } = useContext(TinderContext);
  // const [personId, setPersonId] = useState(people[0].id);
  const [lastFourPhone, setLastFourPhone] = useState(null);

  const location = window.location.pathname;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden p-6">
      <main className="grow">
        {location === '/tinder' && (
          <div>
            <div
              style={{
                backgroundImage: 'url(https://storage.googleapis.com/proudcity/elgl/uploads/2019/09/giphy-1.gif)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100px',
                height: 100,
                margin: 'auto',
                marginBottom: 20,
              }}
            />
            <div style={{ fontSize: 20, color: '#777', textAlign: 'center', marginBottom: 20 }}>
              <p>
                ¿Es posible viajar en el tiempo? No lo sabemos, pero ¿que hubieses hecho si en el 2001 contabas con
                Tinder? Te lo trajimos para que puedas comprobarlo. ¿Tenes huevos para jugar?
              </p>
            </div>
          </div>
        )}
        {location === '/board' && (
          <div>
            <div style={{ fontSize: 20, color: '#777', textAlign: 'center', marginBottom: 20 }}>
              <p>Ingresa para poder dejar mensajes publicos y si queres tambien anonimos</p>
            </div>
          </div>
        )}
        <div>
          <Input
            value={lastFourPhone}
            onChange={e => setLastFourPhone(e.target.value)}
            placeholder="Ultimos 4 numeros de tu celular"
          />
        </div>
        <br />
        <Button
          block
          size="large"
          type="primary"
          onClick={() => {
            const valid = people.find(p => {
              return `${p.lastFourPhone}` === `${lastFourPhone}`;
            });

            if (!valid) {
              message.info('Login Invalido');
            } else {
              setUserId(valid.id);
            }
          }}
        >
          Log In
        </Button>
      </main>
    </div>
  );
};

const Like = ({ like }) => {
  const { userId, loading, setLoading } = useContext(TinderContext);

  const isMatch = like.person.likes.some(p => p.person.id === userId);

  const [deleteLike] = useMutation(DELETE_LIKE, {
    variables: {
      id: like.id,
    },
    refetchQueries: [
      {
        query: GET_PERSON,
        variables: { id: userId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { person } = like;

  return (
    <div
      style={{
        borderRadius: 15,
        overflow: 'hidden',
        border: 'none',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.6)',
        backgroundImage: `url(${person.image?.url})`,
        backgroundSize: 'cover',
        backgroundColor: '#222',
        backgroundPosition: 'center',
        height: window.innerWidth,
        width: window.innerWidth - 70,
        maxWidth: 600,
        maxHeight: 700,
        padding: 20,
        position: 'relative',
        margin: '30px 10px',
      }}
      className="mt-4"
    >
      <div
        style={{ position: 'absolute', top: 0, right: 20, color: '#F260C2', fontSize: 40 }}
        onClick={() => {
          if (loading) return;

          setLoading(true);
          deleteLike({
            variables: {
              id: like.id,
            },
          }).then(() => setLoading(false));
        }}
      >
        {loading ? <LoadingOutlined /> : <HeartFilled />}
      </div>
      {isMatch && (
        <div
          style={{
            borderRadius: 10,
            color: '#222',
            backgroundColor: '#F1C102',
            fontSize: 25,
            fontWeight: 'bold',
            width: 105,
            padding: '0px 10px',
            position: 'absolute',
            transform: 'rotate(-3deg)',
          }}
        >
          Match!
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          bottom: 10,
          left: 20,
          textShadow: '0px 0px 15px rgba(0,0,0,0.6)',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: 25, lineHeight: 0, marginBottom: 10, color: COLORS[person.house] }}>
          {person.house}
        </div>
        <div
          style={{
            fontSize: 40,
            fontWeight: 'bold',
          }}
        >
          {person.name}
        </div>
      </div>
    </div>
  );
};

const LikeModal = ({ person, setVisible, visible }) => {
  const { loading, setLoading, people } = useContext(TinderContext);
  const [search, setSearch] = useState('');
  const likeable = people.filter(p => p.id !== person.id);

  const refetchQueries = [
    {
      query: GET_PERSON,
      variables: { id: person.id },
    },
  ];

  const [createLike] = useMutation(CREATE_LIKE);
  const [publishLike] = useMutation(PUBLISH_LIKE, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [deleteLike] = useMutation(DELETE_LIKE, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        setSearch('');
      }}
      footer={null}
      title="Dale Like!"
      bodyStyle={{ padding: 0 }}
    >
      <div>
        <div style={{ padding: 10 }}>
          <Input value={search} onChange={e => setSearch(e.target.value)} placeholder="Busca a alguien..." />
        </div>
        {likeable
          .filter(p => {
            if (search.length === 0) return true;
            return p.name.toLowerCase().includes(search.toLowerCase());
          })
          .map(p => {
            const personLike = person.likes.find(l => l.person.id === p.id);
            let icon = <HeartOutlined />;

            if (loading === p.id) {
              icon = <LoadingOutlined />;
            } else if (personLike) {
              icon = <HeartFilled />;
            }

            return (
              <div
                key={p.id}
                style={{ display: 'flex', alignItems: 'center', padding: 10, borderTop: '1px solid #eee' }}
              >
                <div>
                  <Avatar src={p.image?.url} size={80} shape="square" />
                </div>
                <div style={{ flex: 1, paddingLeft: 20, fontWeight: 'bold', fontSize: 20 }}>
                  <div>{p.name}</div>
                  <div style={{ color: COLORS[p.house], fontSize: 16 }}>{p.house}</div>
                </div>
                <div>
                  <Button
                    style={{ color: personLike ? 'red' : '#777' }}
                    icon={icon}
                    onClick={async () => {
                      setLoading(p.id);

                      if (personLike) {
                        await deleteLike({
                          variables: {
                            id: personLike.id,
                          },
                        });
                      } else {
                        const r = await createLike({
                          variables: {
                            authorId: person.id,
                            personId: p.id,
                          },
                        });

                        await publishLike({
                          variables: {
                            id: r.data.createLike.id,
                          },
                        });
                      }

                      setLoading(false);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

const InnerUser = ({ person }) => {
  const [visible, setVisible] = useState(false);

  return (
    <section>
      <div className="max-w-6xl mx-auto sm:px-6 relative">
        <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <div data-aos="fade-up" style={{ color: 'white', marginRight: 20, flex: 1 }}>
            <div className="h2">{person.name}</div>
            <div style={{ color: COLORS[person.house], fontSize: 20, fontWeight: 'bold' }}>{person.house}</div>
          </div>
          <Avatar size={64} src={person.image?.url} shape="square" style={{ marginTop: -10 }} />
        </div>
        <div className="mt-4">
          <LikeModal visible={visible} setVisible={setVisible} person={person} />
          <div style={{ fontSize: 20, color: '#777', marginBottom: 20 }}>
            {person.likes.length === 0 && (
              <p>
                ¿Es posible viajar en el tiempo? No lo sabemos, pero ¿que hubieses hecho si en el 2001 contabas con
                Tinder? Te lo trajimos para que puedas comprobarlo. ¿Tenes huevos para jugar?
              </p>
            )}
          </div>
          <Button size="large" block type="primary" onClick={() => setVisible(true)}>
            Agregar Like
          </Button>
        </div>
        <div className="mb-10">
          {person.likes.map(like => (
            <Like key={like.id} like={like} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Tinder = () => {
  const { userId } = useContext(TinderContext);

  const { data, loading } = useQuery(GET_PERSON, {
    variables: { id: userId },
    pollInterval: 5000,
  });

  if (loading) return <div>Loading...</div>;

  const { person } = data;

  return <InnerUser person={person} />;
};

const Loading = () => {
  const { loading } = useContext(TinderContext);

  if (!loading) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 25,
        left: '50%',
        zIndex: 100,
      }}
    >
      <Spin />
    </div>
  );
};

export const WithApollo = ({ children }) => {
  const apolloClient = new ApolloClient({
    uri: GRAPHQL,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const Outer = () => {
  return (
    <TinderProvider>
      <WithApollo>
        <Header />
        <Loading />
        <div className="flex flex-col min-h-screen overflow-hidden p-6">
          <main className="grow">
            <div className="relative pt-12 pb-10 md:pt-40 md:pb-16">
              <Tinder />
            </div>
          </main>
        </div>
      </WithApollo>
    </TinderProvider>
  );
};

export default Outer;
